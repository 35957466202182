$(document).foundation();

var url = window.location.pathname;;
url = url.replace('/', '');

if (url.indexOf('about') >= 0) {
  $('#aboutLink').addClass('navSelected');
} else {
  $('#portLink').addClass('navSelected');
}

$('a').each(function (index, value) {
  if (($(this).attr('href')) == url) {
    $(this).parent('div').css('display', 'none');
  }
});


document.onscroll = function () {
  if (window.innerHeight + window.scrollY > document.body.clientHeight) {
    document.getElementById('nav').style.display = 'none';
  }
}
/*
document.querySelector('.iso_bg img').addEventListener('load', (event) => {

  let cordinates = document.querySelector('.image-holder img').getBoundingClientRect();
  let imageX = (cordinates.left + window.scrollX + cordinates.right) / 2;
  let imageY = (cordinates.top + window.scrollY + cordinates.bottom) / 2;

  const ANGLE_COMPENSATION = 10;
  document.querySelector('.image-holder').addEventListener('mousemove', (event) => {
    let mouseX = event.clientX;
    let mouseY = event.clientY;

    let xOffset = imageX - mouseX;
    let yOffset = imageY - mouseY;

    let xRotationAngle = yOffset / ANGLE_COMPENSATION;
    let yRotationAngle = xOffset / ANGLE_COMPENSATION;

    document.querySelector('.image-holder img').style.transform = "rotateX(" + xRotationAngle + "deg) rotateY(" + yRotationAngle + "deg) "

  })
})*/
